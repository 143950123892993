@import "variable.scss";
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
body {
    background-color: $body_bg !important;
    font-family: $poppinsfonts !important;
    font-size: $normal_txt !important;
    min-height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    color: $black;
}

.selectinput {
    font-size: 14px;
    color: $black;
    padding-left: -10 !important;
}

.red-color {
    color: red;
}

.content-wrapper {
    padding: 0px 20px;
}

.auth_screen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .auth_box {
        margin: 0 auto;
        min-width: 460px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 25px;
        background-color: #fff;
        box-shadow: 0px 0px 10px 0px #00000033;
    }
    .link_txt {
        color: $black;
        font-size: $small_txt;
        font-family: $poppinsfonts;
        font-weight: $regular;
        cursor: pointer;
        &:hover {
            color: $red_color;
        }
    }
}

.form-group {
    label {
        font-size: $form_txt;
    }
    .form-control {
        border: 1px solid $border_color;
        background-color: $body_bg;
        padding: 10px;
        font-size: $form_txt;
        font-family: $poppinsfonts;
        font-weight: $regular;
        color: $black;
        &::placeholder {
            color: $placeholder_color;
        }
        &:focus {
            outline: none;
            box-shadow: none;
            border: 1px solid $border_color;
            background-color: $body_bg;
        }
    }
}

.btn {
    border: none !important;
    cursor: pointer !important;
    border-radius: 6px !important;
    padding: 8px 20px !important;
    font-size: $small_txt !important;
    font-family: $rubikfonts !important;
    font-weight: $medium !important;
    text-transform: capitalize !important;
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.btn-default {
    border: 1px solid #ddd !important;
    color: #444 !important;
}

.fill_btn {
    background: $fill_bg !important;
    color: $white !important;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: $red_color;
}

p {
    line-height: normal;
}

.error-message {
    margin-top: .25rem;
    color: $red_color;
}

a {
    font-family: $poppinsfonts;
    font-size: $form_txt;
    font-weight: $regular;
    cursor: pointer;
    color: $red_color;
    text-decoration: none;
    background-color: transparent;
    &:hover {
        color: $red_color;
    }
    .fa-trash-color {
        color: $red_color;
    }
}

.main-sidebar {
    background-color: $white !important;
    position: fixed !important;
    box-shadow: 0px 2px 10px $box_shadow_color !important;
    .brand-link {
        background-color: $white !important;
        height: 70px !important;
        border: none !important;
        padding: 15px 5px 10px;
        img {
            margin: 0 auto !important;
            opacity: 1 !important;
        }
    }
    .sidebar {
        padding-top: 70px;
        padding-left: 0;
        padding-right: 0;
        a {
            color: $black;
        }
    }
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active,
.sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
    background-color: transparent !important;
    box-shadow: none !important;
    color: $red_color !important;
    font-weight: $medium;
    display: flex;
    align-items: center;
    position: relative;
    &::before {
        content: "";
        width: 4px;
        height: 100%;
        background-color: $red_color;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.sidebar-mini {
    .small-logo {
        display: none;
    }
    &.sidebar-collapse {
        .big-logo {
            display: none;
        }
        .small-logo {
            display: block;
        }
    }
    .main-sidebar {
        &:hover {
            .big-logo {
                display: block;
            }
            .small-logo {
                display: none;
            }
        }
        .sidebar {
            overflow-x: hidden;
        }
    }
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: $red_color;
    border-color: $red_color;
}

.profile-img {
    object-fit: cover;
    height: 25px;
    width: 25px;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: $red_color;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.table {
    margin-top: 10px;
    tr {
        th {
            font-size: $form_txt;
            border: none;
            padding: 10px;
            color: $white;
            background-color: $table_header_bg;
        }
        td {
            font-size: $form_txt;
            padding: 10px;
            word-break: break-word !important;
            // width: 5%;
        }
    }
}

.action_icon {
    i {
        font-size: $normal_txt;
    }
}

.right_section_action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .search_section {
        max-width: 280px;
        position: relative;
        i {
            position: absolute;
            left: 10px;
            top: 10px;
            font-size: $form_txt;
            color: $black;
        }
        input {
            font-size: $form_txt;
            padding: 10px 10px 10px 30px;
            height: 34px;
        }
    }
    .filter_Section {
        margin: 0 10px;
        select {
            height: 34px;
            color: $black;
            font-size: $form_txt;
            padding: 5px 20px 5px 10px;
        }
    }
}

.tab_section {
    ul {
        border: transparent;
        li {
            a {
                color: $black;
                font-size: $form_txt;
                position: relative;
                &:hover {
                    border-color: transparent !important;
                    color: $red_color;
                }
                &.active {
                    border: transparent;
                    color: $red_color !important;
                    &::after {
                        content: "";
                        width: 100%;
                        height: 2px;
                        background-color: $red_color;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}

.back_icon {
    cursor: pointer;
    height: 25px;
    width: 25px;

}

.avatar-upload {
    position: relative;
    max-width: 300px;
    background: #eee;
    .avatar-edit {
        position: absolute;
        right: 12px;
        z-index: 1;
        top: 10px;
        input {
            display: none;
            +label {
                display: inline-block;
                width: 34px;
                height: 34px;
                margin-bottom: 0;
                border-radius: 100%;
                background: #ffffff;
                border: 1px solid transparent;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                cursor: pointer;
                font-weight: normal;
                transition: all 0.2s ease-in-out;
                &:hover {
                    background: #f1f1f1;
                    border-color: #d6d6d6;
                }
                &:after {
                    content: "\f303";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    color: #757575;
                    position: absolute;
                    top: 5px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }
    .swal2-title {
        font-size: 15px !important;
        font-weight: 600;
    }
    .swal2-styled.swal2-confirm {
        background: transparent linear-gradient(190deg, #FFB200 0%, #FF1B00 100%) 0% 0% no-repeat padding-box !important;
        border-radius: 4px !important;
        border: none !important;
        color: #fff !important;
        padding: 5px 15px !important;
        width: 150px !important;
        // color: #fff !important;
        font-size: 14px !important;
        // font-family: "RubikMedium" !important;
        // border-radius: 6px !important;
        height: 40px !important;
        // border: none !important;
        // display: inline;
        // background: linear-gradient(210deg, #ffb200 0%, #ff1b00 100%) !important;
    }
    .swal2-styled {
        padding: 0 !important;
    }
    .swal2-styled.swal2-cancel {
        border: 1px solid #FF1B00 !important;
        background-color: transparent !important;
        font-size: 14px !important;
        color: #FF2200 !important;
        padding: 5px 10px !important;
        border-radius: 4px !important;
        height: 40px !important;
        width: 150px !important;
    }
    .avatar-preview {
        width: 300px;
        height: 200px;
        position: relative;
        border: 6px solid #f8f8f8;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        div {
            width: 100%;
            height: 100%;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 25px;
            color: #bebdbd;
            font-weight: bold;
        }
    }
}

.swal2-styled.swal2-cancel {
    border: 1px solid #FF1B00 !important;
    background-color: transparent !important;
    font-size: 14px !important;
    color: #FF2200 !important;
    padding: 5px 10px !important;
    border-radius: 4px !important;
    height: 40px !important;
    width: 150px !important;
}
  .avatar-preview {
    width: 300px;
    height: 200px;
    position: relative;
    border: 6px solid #f8f8f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

    div {
      width: 100%;
      height: 100%;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      color: #bebdbd;
      font-weight: bold;
    }
  }

  .fa-question-circle:before {
      content: "\f059";
      font-size: 1.3rem;
  }

input[type=date].form-control, input[type=datetime-local].form-control, input[type=month].form-control, input[type=time].form-control {
   height: 34px;
}
.fa-pencil:before {
  content: "\f040";
  color: #eb9558;
}

.swal2-styled.swal2-confirm {
  background: transparent linear-gradient(190deg, #FFB200 0%, #FF1B00 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  border: none !important;
  color: #fff !important;

  padding: 5px 15px !important;
  width: 150px !important;
 
font-size: 14px !important;
 
height: 33px !important;
 
}

.swal2-styled {
  padding: 0 !important;
}

.swal2-styled.swal2-cancel {
  border: 1px solid #FF1B00 !important;
  background-color: transparent !important;
  font-size: 14px !important;
  color: #FF2200 !important;
  padding: 5px 10px !important;
  border-radius: 4px !important;
  height: 33px !important;
  width: 150px !important;
}

.table-title {
    font-size: $title_txt;
    color: $black;
    font-weight: $medium;
    text-transform: capitalize;
    padding: 25px 20px 10px;

}


.custom-img-profile-cls{
            width: 455px !important;
            height: 613px !important;
            object-fit: contain;
            margin: 0 auto;
            margin-bottom: 1rem;
}

// .box_wraper.profile_info_section.create-album-section.fix-contant.video-custom-cls-add video {
//     width: 455px ;
//     height: 613px ;
//     object-fit: contain;
//     margin: 0 auto;
//     margin-bottom: 1rem;
// }
.video-custom-cls-add {
    .custom-img-profile-cls {
        width: 455px !important;
        height: 613px !important;
        object-fit: contain;
        margin: 0 auto;
        margin-bottom: 1rem;
    }
    .default-custom-video-cls {
        width: 455px !important;
        height: 613px !important;
        object-fit: contain;
    }
}
.modal-dialog .modal-content {
    position: unset;
    border-radius: 15px;
}
@media only screen and (max-width: 1224px) {
    .video-custom-cls-add {
        .custom-img-profile-cls {
            width: 335px !important;
            height: 343px !important;
        }
        .default-custom-video-cls {
            width: 335px !important;
            height: 343px !important;
        }
    }
    .default-custom-video-cls {
        width: 335px !important;
        height: 343px !important;
    }
}
@media only screen and (min-width: 1200px) and (max-height: 713px) {
    .gym-imgs {
        height: 513px !important;
    }
    .scrolling-same-right {
        width: 313px !important;
    }
    .computer-audio {
        height: 363px !important;
        margin-bottom: 30px;
    }
    .scrolling-same-height {
        &:before {
            height: 363px !important;
        }
    }
}
.computer-audio {
   
    margin-bottom: 30px;
}
