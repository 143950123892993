@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
//Font Weight
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
//Font Family
$poppinsfonts: 'Poppins',
sans-serif;
$rubikfonts: 'Rubik',
sans-serif;
// Color 
$body_bg:#F5F5F5;
$black: #000000;
$white:#fff;
$border_color: #C7C7C780;
$placeholder_color :#999999;
$fill_bg: transparent linear-gradient(210deg,
#FFB200 0%,
#FF1B00 100%) 0% 0% no-repeat padding-box;
$red_color: #FF1B00;
$form_control_placeholder: #999999;
$box_shadow_color: #0000000F;
$table_header_bg: #fd3504;
$qr_btn_border_color: #212121;

$checkbox_border: #C7C7C7;

$text-color:#6c6c6c;
// Font size
$small_txt: 12px;
$form_txt: 14px;
$normal_txt: 16px;
$title_txt: 22px;
$total-users:35px;